import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomePageModule } from './views/home/home.module';
import { MuslimMusicModule } from './views/muslim-music/muslim-music.module';
import { MuslimPillarsModule } from './views/muslim-pillars/muslim-pillars.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => HomePageModule
  },
  {
    path: 'products',
    redirectTo: 'muslim-pillars'
  },
  {
    path: 'wquran',
    redirectTo: 'muslim-pillars'
  },
  {
    path: 'muslim-pillars',
    loadChildren: () => MuslimPillarsModule
  },
  {
    path: 'muslim-music',
    loadChildren: () => MuslimMusicModule
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        preloadingStrategy: PreloadAllModules,
        // scrollPositionRestoration: 'enabled',
        // scrollOffset: [0, 64],
        useHash: false, anchorScrolling: 'enabled'
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
