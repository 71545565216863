import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
    @Inject(HttpClient) private http: HttpClient) {
  }
  sendEmail(payload: { name: string; message: string; email: string }): Observable<any> {
    const route = 'https://wQuran.azee.tech/mail/sendemail';
    return this.http.post<any>(route, payload);
  }
}
