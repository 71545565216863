import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/screen-size-service.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit, OnDestroy {
  isDesktop: boolean;
  protected subscriptions: Subscription[] = [];

  constructor(private screenSizeService: ScreenSizeService) {

    const s = this.screenSizeService.isDesktopView()
      .subscribe(isDesktop => {
        this.isDesktop = isDesktop;
      }) as any;

    this.subscriptions.push(s);
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((s) => s?.unsubscribe());
  }

  ngOnInit() { }

}
