import { Component, HostListener } from '@angular/core';
import { ScreenSizeService } from './services/screen-size-service.service';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private screenSizeService: ScreenSizeService,
  ) {
    this.screenSizeService.onResize(this.platform.width());
  }
  @HostListener('window:resize', ['$event'])
  private onResize(event: { target: { innerWidth: number } }) {
    this.screenSizeService.onResize(event.target.innerWidth);
  }
}
