import { UntypedFormGroup } from '@angular/forms';

export class ReactiveFormValidator {
  formSubmitAttempt = false;
  private form: UntypedFormGroup;
  constructor(form: UntypedFormGroup) {
    this.form = form;
  }
  isFieldValid = (field: string, error: any) => this.form.get(field).hasError(error.type) && this.isInvalid(field);
  isValid = (field: string): boolean => {
    const { dirty, touched } = this.form.get(field);
    return this.form.get(field).valid && (dirty || touched);
  };
  isInvalid = (field: string) => {
    const { dirty, untouched, touched } = this.form.get(field);
    return (this.form.get(field).invalid && (touched || dirty))
      || (this.form.get(field).invalid && untouched && this.formSubmitAttempt);
  };
}
