import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu.component';

@NgModule({
    declarations: [MenuComponent],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule
    ],
    exports: [MenuComponent]
})
export class MenuComponentModule {}
