import { Component, OnInit } from '@angular/core';
import { ScreenSizeService } from '../../../services/screen-size-service.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  isDesktop: boolean;
  constructor(
    private screenSizeService: ScreenSizeService
  ) {
    this.screenSizeService.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    });
  }
  ngOnInit() { }
}
