import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingController, ToastController } from '@ionic/angular';
import { ReactiveFormValidator } from '../../models/validator';
import { MailService } from '../../services/mail.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {

  form: UntypedFormGroup;
  validator: ReactiveFormValidator;
  errorMessages = {
    name: [
      { type: 'required', message: '* Please enter your name' }
    ],
    email: [
      { type: 'required', message: '* Please enter your email address' },
      { type: 'pattern', message: '* Please enter a valid email address' }
    ],
    message: [
      { type: 'required', message: '* Please enter your message' },
    ],
  };
  private toast: HTMLIonToastElement;
  private formSubmitAttempt: boolean;
  constructor(
    private mailSvc: MailService,
    private loadingController: LoadingController,
    private toastController: ToastController) { }

  ngOnInit() {
    this.createReactiveForm();
    this.validator = new ReactiveFormValidator(this.form);
  }
  createReactiveForm() {
    const emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, , Validators.pattern(emailPattern)]),
      message: new UntypedFormControl(null, [Validators.required])
    });
  }
  onSubmit() {
    if (this.form.valid) {
      this.showLoader()
        .then(() => {
          this.mailSvc.sendEmail(this.form.value)
            .subscribe(() => {
              this.loadingController.dismiss();
              if (this.toast) {
                this.toast.dismiss();
              }
              this.showToast('Message Received', 'Thank you for your message. We will soon contact you.', 4000, 'success');
              this.resetAfterSubmit();
            }, (err: any) => {
              this.loadingController.dismiss();
              this.showToast('Message Failed', err.message, 4000);
            });
        });
    }
  }
  resetAfterSubmit() {
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
  }
  private async showLoader(message: string = 'Please wait...') {
    const loading = await this.loadingController.create({
      message
    });
    return await loading.present();
  }
  private async showToast(header: string, message: string, duration: number = -1, color: string = 'danger') {
    this.toast = await this.toastController.create({
      header,
      color,
      message,
      duration,
      position: 'bottom',
      buttons: [
        {
          text: 'OK',
          role: 'cancel'
        }
      ]
    });
    await this.toast.present();
  }
}
