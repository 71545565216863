import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private isDesktop = new BehaviorSubject<boolean>(this.isMobileDevice());
  constructor() { }
  onResize(size: number): void {
    if (size <= 768) {
      this.isDesktop.next(false);
    } else {
      this.isDesktop.next(true);
    }
  }
  isDesktopView(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }
  isMobileDevice(): boolean {
    return !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  }
}
