import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MuslimPillarsRoutingModule } from './muslim-pillars-routing.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PageHeaderComponentModule } from '../components/page-header/page-header.component.module';
import { PageFooterComponentModule } from '../components/page-footer/page-footer.module';
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';


@NgModule({
  declarations: [HomeComponent, PrivacyComponent],
  imports: [
    CommonModule,
    MuslimPillarsRoutingModule,
    CommonModule,
    FormsModule,
    IonicModule,
    PageHeaderComponentModule,
    PageFooterComponentModule
  ]
})
export class MuslimPillarsModule { }
