import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HomePageRoutingModule } from './home-routing.module';
import { HomePage } from './home.page';
import { PageHeaderComponentModule } from '../components/page-header/page-header.component.module';
import { PageFooterComponentModule } from '../components/page-footer/page-footer.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    HomePageRoutingModule,
    PageHeaderComponentModule,
    PageFooterComponentModule
  ],
  declarations: [HomePage]
})
export class HomePageModule { }
