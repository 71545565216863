import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ScreenSizeService } from '../../../services/screen-size-service.service';

interface IPage {
  id: number;
  title: string;
  url?: string;
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  isDesktop: boolean;
  slideLineWidth: string;
  slideLineLeft: string;
  isHover = false;
  scrollToTopOpacity: 0;
  appPages: IPage[] = [
    {
      id: 1,
      title: 'Home',
    },
    {
      id: 3,
      title: 'Products',
      url: '/products'
    }, {
      id: 2,
      title: 'Contact Us '
    }

  ];
  public selectedIndex = 0;
  constructor(
    private screenSizeService: ScreenSizeService,
    private menu: MenuController,
    private router: Router) {
    this.screenSizeService.isDesktopView().subscribe((isDesktop: boolean) => {
      if (this.isDesktop && !isDesktop) {
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
  }
  ngOnInit() {
    const path = window.location.pathname.split('web/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }
  openEnd() {
    this.menu.open('end');
  }
  openCustom() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }
  positionLine(event: any) {
    if (event.type === 'mouseout') {
      this.slideLineWidth = '0px';
      this.isHover = false;
    } else {
      this.isHover = true;
      const left = event.srcElement.offsetLeft + 'px';
      this.slideLineLeft = left;
      this.slideLineWidth = event.srcElement.offsetWidth + 'px';
    }
  }
  onMobileMenuClicked(p: IPage) {
    this.menu.close();
    this.openEnd();
    this.performMenuOperation(p);
  }
  performMenuOperation(p: IPage) {
    switch (p.id) {
      case 1:
        this.router.navigateByUrl('/home').then(() => {
          this.scrollToHome();
        });
        break;
      case 2:
        this.router.navigateByUrl('/home').then(() => {
          this.scrollToContact();
        });
        break;
      default:
        this.router.navigate([`${p.url}`]);
        break;
    }
  }
  scrollToContact() {
    const anchor = document.getElementById('contact');
    anchor.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }
  navigateToRoot() {
    this.router.navigateByUrl('/');
  }
  scrollToHome() {
    const anchor = document.getElementById('home');
    anchor.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }
}
