import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements OnInit {
  constructor(private router: Router) { }
  ngOnInit() { }
  scrollToContact() {
    this.router.navigateByUrl('/home').then(() => {
      const anchor = document.getElementById('contact');
      anchor.scrollIntoView({ block: 'end', behavior: 'smooth' });
    });
  }
  scrollToHome() {
    this.router.navigateByUrl('/home').then(() => {
      const anchor = document.getElementById('home');
      anchor.scrollIntoView({ block: 'center', behavior: 'smooth' });
    });
  }
}
